'use strict';

angular.module('informaApp')
    .directive('infSaveSearch', ["$timeout", "SaveSearchSvc", "OmnitureSvc", "ToastrHelper", "$rootScope", "ConstantsSvc",
        function ($timeout, SaveSearchSvc, OmnitureSvc, ToastrHelper, $rootScope, ConstantsSvc) {

            return {
                restrict: 'E',
                templateUrl: 'directives/inf-save-search/template.ptl.html',

                scope: {
                    getData: '=',
                    tooltip: "="
                },

                link: function (scope, element, attr) {
                    var placeholder = 'Selection ',
                        modal = element.find('#saveSelectionConfirm');

                    scope.editable = false;
                    scope.search = {};

                    scope.createInput = function () {
                        if (!scope.savedSearches){
                            return;
                        }

                        scope.editable = !scope.editable;

                        scope.search.name = placeholder + getNewNumber();

                        $timeout(function () {
                            element.find('#search-name').focus()
                        });
                    };

                    function closeModalByKeypress(event) {
                        if (event.which === 13) {
                            scope.saveSelection('replace');
                            document.body.removeEventListener('keyup', closeModalByKeypress);
                            modal.modal('hide');
                        }
                    }

                    scope.checkSelectionName = function () {
                        scope.exists = false;
                        scope.savedSearches.forEach(function (search) {
                            if (search != null && search.name === scope.search.name) {
                                scope.exists = search.id
                            }
                        });

                        if (scope.exists) {
                            modal.modal('show');
                            document.body.addEventListener('keyup', closeModalByKeypress);
                        } else {
                            scope.saveSelection();
                        }
                    };

                    scope.keypressHandler = function (event) {
                        if (event.which === 13) {
                            scope.checkSelectionName();
                            event.stopPropagation();
                            event.preventDefault();
                        }
                    };

                    scope.saveSelection = function (update) {
                        var action = 'saveSearch';

                        if (update) {
                            action = 'updateSearch';
                            scope.search.id = scope.exists;
                        }

                        const searchData = scope.getData();
                        scope.search.search = searchData.search;
                        scope.search.referrerType = searchData.referrerType;

                        var name = scope.search.name;

                        return SaveSearchSvc[action](scope.search)
                            .then(function (x) {
                                scope.getSearches();

                                scope.editable = !scope.editable;

                                scope.exists = null;

                                ToastrHelper.showSuccess("The search has been <strong>successfully saved</strong> as " + name);
                                $rootScope.$broadcast(ConstantsSvc.EVENTS.SearchSaved);

                                OmnitureSvc.trackSaveSelection();
                            })
                    };

                    scope.getSearches = function () {
                        return SaveSearchSvc.allSearchesByUserId()
                            .then(function (res) {
                                scope.savedSearches = res.data.data;
                            });
                    };

                    function getNewNumber() {
                        var values = scope.savedSearches.map(function (x) {
                            if (x == null) {
                                return 0;
                            }

                            var currentString = x.name;
                            var matcher = currentString.match(/^Selection\s(\d+)$/i);

                            return (matcher !== -1 && matcher != null) ? +matcher[1] : 0;
                        });

                        var max = _.max(values);

                        return !max ? 1 : max + 1;
                    }

                    scope.getSearches();

                    scope.cancel = function () {
                        scope.editable = !scope.editable;

                        ToastrHelper.showWarning("Saving has been canceled!");
                    };
                }
            }
        }]);